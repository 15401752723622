<template>
  <div class="index">
    <div class="head">
      <div class="head_content">
        <div class="search">
          <el-input v-model="input" placeholder="请输入内容"></el-input>
          <div class="icon">
            <el-image
              class="search_icon"
              :src="require('@as/search/search_icon.png')"
            ></el-image>
          </div>
        </div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item
            :index="index"
            v-for="(item, index) in menuList"
            v-bind:key="item"
            >{{ item }} (89)</el-menu-item
          ></el-menu
        >
      </div>
    </div>
    <div class="main">
      <div class="list">
        <div class="list_item_1">
          <div class="buoy">观点</div>
          <el-image
            class="list_item_img"
            :src="require('@as/index/banner.png')"
          ></el-image>
          <div class="list_item_content">
            <div class="title">疫情连着你和我：听女律师们如是说</div>
            <div class="subtitle">
              债券虚假陈述诉讼的纠纷解决需兼顾不同的上位法和交易规则，简单适用股票模型下的司只是治
            </div>
            <div class="info">
              <el-image
                class="info_icon"
                :src="require('@as/index/icon_1.png')"
              ></el-image>
              <div class="info_name">张保生 周伟 朱媛媛</div>
              <div class="info_tag_container">
                <div class="info_tag">九民纪要</div>

                <div class="info_tag">九民纪要</div>
              </div>
              <el-image
                class="info_icon_time"
                :src="require('@as/search/icon_1.png')"
              ></el-image>
              <div class="info_name">2354</div>
              <el-image
                class="info_icon_time"
                :src="require('@as/index/icon_2.png')"
              ></el-image>
              <div class="info_name">10分钟前</div>
            </div>
          </div>
        </div>
        <div class="list_item_1 no_img">
          <div class="list_item_content">
            <div class="title">疫情连着你和我：听女律师们如是说</div>
            <div class="subtitle">
              企业通过DevOps体系落地，建立相关的DevOps工具及流程规范，实现CICD过程自动化。在整个CICD过程中，测试是其重要组成一环，由于测试的方式较多，在传统的测试设计里面，尚未有哪家测试工具实现了所有测试方式的全覆盖，而今天基于DevOps平台的自动化驱动与扩展能力，可以实现测试服务的一体化设计。
            </div>
            <div class="info">
              <el-image
                class="info_icon"
                :src="require('@as/index/icon_1.png')"
              ></el-image>
              <div class="info_name">张保生 周伟 朱媛媛</div>
              <div class="info_tag_container">
                <div class="info_tag">九民纪要</div>

                <div class="info_tag">九民纪要</div>
              </div>
              <el-image
                class="info_icon_time"
                :src="require('@as/search/icon_1.png')"
              ></el-image>
              <div class="info_name">2354</div>
              <el-image
                class="info_icon_time"
                :src="require('@as/index/icon_2.png')"
              ></el-image>
              <div class="info_name">10分钟前</div>
            </div>
          </div>
        </div>
        <div class="list_item_1">
          <div class="left_img_container">
            <el-image
              class="item_img"
              :src="require('@as/index/banner.png')"
            ></el-image>
            <div class="content">
              <div class="title">建站达人秀</div>
              <div class="subtitle">19 篇文章 · 1234 订阅者</div>
            </div>
            <div class="btn">订阅</div>
          </div>
        </div>
        <div class="list_item_1">
          <div class="answer">
            <div class="answer_head">
              <div class="answer_tag">
                <p>12</p>
                <p>回答</p>
              </div>
              <div class="head_info">
                <div class="title">机器学习的发展前景如何？</div>
                <div class="subtitle">
                  <div class="tag">融资并购</div>
                  <div class="tag">融资并购</div>
                  <el-image
                    class="info_icon"
                    :src="require('@as/search/icon_1.png')"
                  ></el-image>
                  <div class="info_time">10分钟前</div>
                </div>
              </div>
            </div>
            <div class="middle">
              <el-image
                class="middle_icon"
                :src="require('@as/search/icon_3.png')"
              ></el-image>
              <div class="middle_name">我来回答</div>
              <el-image
                class="middle_icon"
                :src="require('@as/search/icon_2.png')"
              ></el-image>
              <div class="middle_name">关注问题</div>
            </div>
            <div class="detail">
              君合深知律所的核心竞争力在于人才，始终不遗余力吸引最优秀的法律人才加入团队。君合的律师大多毕业于国内外顶尖的法律院校，不少成员曾是资深的政府官员...
            </div>
          </div>
        </div>
        <div class="list_item_1">
          <div class="left_item">
            <div class="item_title">国际争端解决领域专业律师</div>
            <div class="item_subtitle">
              <span>10-15K</span>
              <div class="info">
                <p>/月</p>
                <p>北京</p>
                <p>法学硕士</p>
                <p>1-2年</p>
              </div>
            </div>
            <div class="item_info">
              <el-image
                class="info_icon"
                :src="require('@as/index/icon_2.png')"
              ></el-image>
              <div class="info_name">10分钟前</div>
              <el-image
                class="info_icon"
                :src="require('@as/search/icon_4.png')"
              ></el-image>
              <div class="info_name">收藏</div>
            </div>
          </div>
          <div class="right_item">
            <div class="top">
              <el-image
                class="top_img"
                :src="require('@as/index/icon_2.png')"
              ></el-image>
              <div class="name">
                <div>君合律师事务所</div>
                <div class="subtitle">企业规模500人以上</div>
              </div>
            </div>
            <div class="tag_group">
              <div class="tag">五险一金</div>
            </div>
          </div>
        </div>
        <div class="list_item_1">
          <div class="left_img_container">
            <el-image
              class="item_img"
              :src="require('@as/index/banner.png')"
            ></el-image>
            <div class="content">
              <div class="title">建站达人秀</div>
              <div class="subtitle">19 篇文章 · 1234 订阅者</div>
            </div>
            <div class="btn cancel">取消订阅</div>
          </div>
        </div>
        <div class="list_item_1">
          <div class="left_price_content">
            <el-image
              class="item_img"
              :src="require('@as/index/icon_2.png')"
            ></el-image>
            <div class="info">
              <div class="title">
                兰州局集团公司兰州车辆段关于兰州客整所电梯维修项目招标公告
              </div>
              <div class="price">¥ 580.00</div>
            </div>
          </div>
        </div>
        <div class="list_item_1">
          <div class="right_price_content">
            <el-image
              class="item_img"
              :src="require('@as/index/icon_2.png')"
            ></el-image>
            <div class="info">
              <div class="title">2021考研专业课名师全程班</div>
              <div class="subtitle">
                随着腾讯云 Elasticsearch 云产品功能越来越丰富，ES 用户越来越多…
              </div>
            </div>
            <div class="price">¥ 580.00</div>
          </div>
        </div>
        <div class="list_item_1">
          <div class="left_num_content">
            <el-image
              class="item_img"
              :src="require('@as/index/icon_2.png')"
            ></el-image>
            <div class="info">
              <div class="title">2021考研专业课名师全程班</div>
              <div class="num">
                随着腾讯云 Elasticsearch 云产品功能越来越丰富，ES 用户越来越多…
              </div>
              <div class="num">
                随着腾讯云 Elasticsearch 云产品功能越来越丰富，ES 用户越来越多…
              </div>
            </div>
            <div class="price">¥ 580.00</div>
          </div>
        </div>
      </div>
      <div class="card_list">
        <card v-for="item in 4" v-bind:key="item" class="item" />
      </div>
      <div class="card_list">
        <visitingCard v-for="item in 3" v-bind:key="item" class="visiting" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    card: () => import('../components/event/card'),
    visitingCard: () => import('../components/huangye/card'),
  },
  data() {
    return {
      menuList: [
        '文章',
        '专栏',
        '问答',
        '活动',
        '职位',
        '黄页',
        '用户',
        '服务',
      ],
    }
  },
}
</script>

<style lang="less" scoped>
.index {
  background: #ffffff;
}
.head {
  margin-top: -20px;
  background: #f3fafb;
  border-bottom: 1px solid #dedede;
  .head_content {
    margin: 40px auto 0;
    width: 1200px;
  }
  .search {
    margin-bottom: 10px;
    display: flex;
    width: 940px;
    & /deep/ .el-input__inner {
      height: 46px;
      line-height: 46px;
      border-radius: 4px 0 0 4px;
    }
    .icon {
      padding: 11px 0;
      width: 80px;
      height: 46px;
      text-align: center;
      background: #1c9ab1;
      .search_icon {
        width: 24px;
        height: 24px;
      }
    }
  }
  & /deep/ .el-menu {
    background: transparent;
  }
  & /deep/ .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  & /deep/ .el-menu-item {
    padding: 0;
    margin-right: 50px;
    font-size: 16px;
    color: #333333;
  }
  & /deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover {
    background: transparent;
  }
  & /deep/ .el-menu--horizontal > .el-menu-item.is-active {
    border-color: #1c9ab1;
    color: #1c9ab1;
  }
  & /deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
    background: transparent;
  }
}
.main {
  margin: 0 auto;
  padding-bottom: 88px;
  width: 1200px;

  .list {
    width: 940px;
    .list_item_1 {
      position: relative;
      margin-top: 20px;
      padding-bottom: 20px;
      display: flex;
      border-bottom: 1px solid #e0e1e2;
      &.no_img {
        .list_item_content {
          margin-left: 0;

          .subtitle {
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #999999;
            line-height: 24px;
            -webkit-line-clamp: 3;
          }
          .info {
            margin-top: 20px;
          }
        }
      }
      .buoy {
        z-index: 2;
        position: absolute;
        top: 6px;
        left: 6px;
        padding: 3px 6px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #ffffff;
        background: rgba(28, 155, 178, 0.5);
      }
      .list_item_img {
        width: 218px;
        height: 136px;
      }
      .list_item_content {
        margin: 6px 0 0 24px;
        flex: 1;
        .title {
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 500;
          color: #333333;
        }
        .subtitle {
          margin-top: 18px;
          font-size: 13px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #a6a7a7;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .info {
          margin-top: 47px;
          display: flex;
          align-items: center;
          .info_name {
            margin-left: 8px;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #afafaf;
            &.active {
              color: #1c9bb2;
            }
          }
          .info_icon {
            margin-left: 16px;
            width: 11px;
            height: 10px;
            &:first-child {
              margin-left: 0;
            }
          }
          .info_tag_container {
            margin-left: 15px;
            flex: 1;
            display: flex;
          }
          .info_tag {
            margin-left: 4px;
            padding: 5px 16px;
            background: #eaedee;
            font-size: 10px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #afafaf;
            &.active {
              color: #fff;
              background: #1c9bb2;
            }
          }
          .info_icon_time {
            margin-left: 26px;
            width: 11px;
            height: 11px;
          }
        }
      }
      .left_img_container {
        flex: 1;
        display: flex;
        align-items: center;
        .item_img {
          width: 90px;
          height: 90px;
          border-radius: 50%;
        }
        .content {
          margin-left: 16px;
          .title {
            font-size: 20px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #666666;
          }
          .subtitle {
            margin-top: 14px;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 500;
            color: #999999;
          }
        }
        .btn {
          margin-left: auto;
          padding: 8px 24px;
          border: 1px solid #4798c6;
          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #4798c6;
          &.cancel {
            border-color: #999999;
            color: #999999;
          }
        }
      }
      .answer {
        flex: 1;
        .answer_head {
          display: flex;
          align-items: flex-start;
          .answer_tag {
            padding: 8px 12px;
            background: #f5f5f7;
            font-size: 10px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #4a97ad;
            p {
              &:first-child {
                font-size: 16px;
              }
            }
          }
          .head_info {
            margin-left: 16px;
            .title {
              font-size: 16px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #333333;
            }
            .subtitle {
              margin-top: 9px;
              display: flex;
              align-items: center;
              .tag {
                margin-right: 8px;
                padding: 6px;
                font-size: 10px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #999999;
                background: #e9eced;
              }
              .info_icon {
                margin-left: 24px;
                width: 12px;
                height: 8px;
              }
              .info_time {
                margin-left: 4px;
                font-size: 12px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #afafaf;
              }
            }
          }
        }
        .middle {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .middle_icon {
            margin-left: 25px;
            width: 13px;
            height: 13px;
          }
          .middle_name {
            margin-left: 4px;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #babbbb;
          }
        }
        .detail {
          margin-top: 8px;
          padding: 15px 20px;
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #808080;
          line-height: 28px;
          background: #f5f5f7;
        }
      }
      .left_item {
        width: 60%;
        .item_title {
          font-size: 17px;
          font-family: PingFangSC;
          font-weight: 500;
          color: #595757;
        }
        .item_subtitle {
          margin-top: 17px;
          display: flex;
          align-items: flex-end;
          span {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #4b99af;
          }
          .info {
            display: flex;
            align-items: center;
            p {
              margin-right: 11px;

              font-size: 14px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #595757;
              &:not(:first-child) {
                padding-left: 11px;
                border-left: 1px solid #595757;
              }
            }
          }
        }
        .item_info {
          margin-top: 23px;
          display: flex;
          align-items: center;
          .info_icon {
            margin-right: 4px;
          }
          .info_name {
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #afafaf;
            &:nth-child(2) {
              margin-right: 11px;
              padding-right: 11px;
              border-right: 2px solid #afafaf;
            }
          }
        }
      }
      .right_item {
        padding-left: 33px;
        border-left: 1px solid #dfdfdf;
        .top {
          display: flex;
          align-items: center;
          .top_img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
          .name {
            margin-left: 6px;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 500;
            color: #595757;
            .subtitle {
              margin-top: 6px;
              font-size: 12px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #afafaf;
            }
          }
        }
        .tag_group {
          margin-top: 30px;
          display: flex;
          .tag {
            padding: 6px 12px;
            font-size: 10px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #ffffff;
            background: #a8cfd2;
            &:not(:first-child) {
              margin-left: 15px;
            }
          }
        }
      }
      .left_price_content {
        display: flex;
        .item_img {
          width: 140px;
          height: 90px;
          border: 1px solid #dedede;
        }
        .info {
          margin-left: 26px;
          .title {
            margin-top: 12px;
            font-size: 16px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #333333;
          }
          .price {
            margin-top: 14px;
            font-size: 18px;
            font-family: PingFangSC;
            font-weight: 500;
            color: #ba4c47;
          }
        }
      }
      .right_price_content {
        flex: 1;
        display: flex;
        align-items: center;
        .item_img {
          width: 140px;
          height: 90px;
        }
        .info {
          flex-grow: 2;
          flex-shrink: 0;
          margin-left: 27px;
          .title {
            font-size: 16px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #333333;
          }
          .subtitle {
            margin-top: 17px;
            width: 318px;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
          }
        }
        .price {
          padding: 22px 0 22px 120px;
          font-size: 16px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #d02c21;
          border-left: 1px solid #dedede;
        }
      }
      .left_num_content {
        flex: 1;
        display: flex;
        align-items: center;
        .item_img {
          width: 140px;
          height: 140px;
        }
        .info {
          flex-grow: 2;
          flex-shrink: 0;
          margin-left: 27px;
          .title {
            margin-bottom: 36px;
            font-size: 16px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #333333;
          }
          .num {
            margin-bottom: 8px;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #999999;
          }
        }
        .price {
          padding: 22px 0 22px 120px;
          font-size: 16px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #d02c21;
          border-left: 1px solid #dedede;
        }
      }
    }
  }
  .card_list {
    margin-top: 29px;
    display: flex;
    justify-content: space-between;
    .item {
      border: 1px solid #dedede;
      margin-bottom: 0;
    }
    .visiting {
      margin-top: 0;
    }
  }
}
</style>